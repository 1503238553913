/* eslint-disable no-param-reassign */
import https from 'https';

let isRefreshing = false;

const waitForNewToken = async () => new Promise((resolve) => {
  const interval = setInterval(() => {
    if (!isRefreshing) {
      resolve(true);
      clearInterval(interval);
    }
  }, 100);
});

export default ({
  app, $axios, store, redirect, $config,
}) => {
  $axios.defaults.baseURL = $config.backendUrl;
  $axios.defaults.timeout = 60000;
  $axios.defaults.httpsAgent = new https.Agent({ keepAlive: true, rejectUnauthorized: false });

  $axios.onRequest(async (config) => {
    if (process.server && store.state.tracking.visitorId) {
      config.headers['visitor-id'] = store.state.tracking.visitorId;
    }

    if (process.server) {
      if (app.context.req.headers.cookie) {
        config.headers.Cookie = app.context.req.headers.cookie;
      }

      if (app.context.req.headers['x-forwarded-for']) {
        const [ip] = (app.context.req.headers['x-forwarded-for'] || '').split(',');
        config.headers['client-ip'] = ip;
      }
    } else if (process.client && store.getters['auth/refreshToken']()) {
      if (config.url !== '/auth/token/refresh') {
        const needToRefresh = await store.dispatch('auth/checkNeedToRefreshAccessToken');
        if (needToRefresh && !isRefreshing) {
          isRefreshing = true;
          await store.dispatch('auth/refreshAccessToken');
          isRefreshing = false;
        } else if (isRefreshing) {
          await waitForNewToken();
        }
      }
    }

    if (process.client) {
      config.headers['Timezone-Offset'] = (new Date().getTimezoneOffset() / 60) * -1;
    }

    if (store.state.tracking.sessionId) {
      config.headers['session-id'] = store.state.tracking.sessionId;
    }

    return config;
  });

  $axios.onError(async (error) => {
    const { noError = false } = error.response ? error.response.config : {};

    if (error.response && error.response.data && error.response.data.maintenance) {
      return redirect('/maintenance/');
    }

    if (!error.response) {
      return Promise.reject(error);
    }

    if (['tokenRequired', 'unauthorized'].includes(error.response.data.error)) {
      return redirect(301, '/login/');
    }

    if (!noError && error.response.status !== 404 && error.response.data.error !== 'certificateNotFound') {
      await store.dispatch('modals/open', {
        name: 'default',
        title: error.response.data.message,
        message: error.response.data.description || '',
      });
    }

    return Promise.reject(error);
  });
};
